import React, { useEffect, useState } from "react";
import classNames from "classnames";
import qs from "query-string";
import { useAppState } from "@state/state";
import {
  SearchFilterBar,
  SearchResults,
  SearchCorQuestionFilter,
} from "@modules/search/index";
import { navigate } from "gatsby";
import { CollectionsPopout } from "@modules/curriculum/index";
import { useCurriculumIndexData } from "@staticQueries";
import { SEO, VideosPopout } from "@common";
import { Text, RichText, Tabs, RandomShapes, Section, Container } from "@ui";
import { t } from "@translations";

/* eslint react/prop-types: 0 */

const CurriculumIndex = ({ location }) => {
  const [{ search }, dispatch] = useAppState();
  const [initialTerm, setInitialTerm] = useState();
  const { filters, options } = search;

  const { meta, hero, filter, collections } = useCurriculumIndexData();

  const setFiltersType = type => {
    const isValidType =
      type === "singles" ||
      type === "lessons" ||
      type === "assessments" ||
      type === "collections";
    const isNewType = type !== filters.type;
    if (isValidType && isNewType) {
      dispatch({
        type: "setFilters",
        filters: {
          ...filters,
          type,
        },
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: "setHeaderTheme",
      headerTheme: {
        bgColor: "blue",
        showSearch: false,
        lockup: {
          size: "small",
          color: "blue",
        },
      },
    });

    dispatch({ type: "deSelectCard" });
  }, []);

  useEffect(() => {
    // set filters based on selected tab
    const { tab, searchTerm } = qs.parse(location.search);

    if (tab) {
      setFiltersType(tab);
    }

    if (searchTerm) {
      setInitialTerm(searchTerm);
    }

    // If no hash, then clear search results.
    // Maintaining search results if there's a hash in the url becasue
    // in that case we're either landing here from a search request or
    // returning from a curriculum single page and the user will likely
    // want to continue browsing their filtered results
    if (!location.hash) {
      dispatch({ type: "clearSearchAndFilters" });
    }
  }, [location.search]);

  const tabs = [
    {
      id: 1,
      title: t("lessons & assessments"),
      onClick: () => {
        navigate("/curriculum/?tab=singles", { replace: false });
        // setFiltersType("singles");
      },
      isSelected: filters.type === "singles",
    },
    {
      id: 2,
      title: t("lessons"),
      onClick: () => {
        navigate("/curriculum/?tab=lessons", { replace: false });
        // setFiltersType("singles");
      },
      isSelected: filters.type === "lessons",
    },
    {
      id: 3,
      title: t("assessments"),
      onClick: () => {
        navigate("/curriculum/?tab=assessments", { replace: false });
        // setFiltersType("singles");
      },
      isSelected: filters.type === "assessments",
    },
    {
      id: 4,
      title: t("collections"),
      onClick: () => {
        navigate("/curriculum/?tab=collections", { replace: false });
        // setFiltersType("collections");
      },
      isSelected: filters.type === "collections",
    },
  ];

  const showCorQuestions = options[filters.type].filters.corQuestion;

  return (
    <>
      <SEO {...meta} />
      <Section size="sm" hasPaddingBottom={false} className="bg-blue">
        <RandomShapes />
        <Container className="relative z-10">
          <div className="text-center mt-12 lg:mt-24 max-w-sm mx-auto text-white">
            <Text preset="h1" className="pt-4">
              {hero.heading}
            </Text>

            <RichText
              html={hero.description.html}
              className="RichText--sans mt-6"
            />
          </div>

          <div id="search-top" className="relative z-10 mt-12 pt-2">
            <Tabs items={tabs} inline />
          </div>
        </Container>
      </Section>
      <Section
        hasPaddingTop={false}
        hasPaddingBottom={false}
        allowOverflow
        className="z-10"
      >
        <Container>
          <SearchFilterBar initialTerm={initialTerm} />
        </Container>
      </Section>
      {showCorQuestions && (
        <SearchCorQuestionFilter prompt={filter.corQuestionPrompt} />
      )}
      <Section size="sm" allowOverflow hasPaddingBottom={false}>
        <SearchResults nullResultsMessage={filter.nullResultsMessage.html} />
      </Section>

      {(filters.type === "singles" ||
        filters.type === "lessons" ||
        filters.type === "assessments") && (
        <Section>
          <CollectionsPopout {...collections} />
        </Section>
      )}

      <Section
        size="sm"
        className={classNames({
          "bg-gray-1 sm:rounded-lg": true,
          "mt-6 md:mt-12": filters.type === "collections",
        })}
      >
        <VideosPopout />
      </Section>
    </>
  );
};

export default CurriculumIndex;
